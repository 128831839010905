import React from "react";

import { isEqual } from "lodash";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { IconArrowLeftRight, Input, UnstyledButton } from "@busbud/horizon";

import { AffiliateCheckbox } from "@app/components/landing-pages/affiliate-checkbox";
import { DatePassengerSearchGroup } from "@app/components/search-form/date-passenger-search-group";
import { LocationGroup } from "@app/components/search-form/location-group";
import type { SearchFormStyleVariant } from "@app/components/search-form/search-form";
import { SearchFormButton } from "@app/components/search-form/search-form-button";
import { SearchFormContainer } from "@app/components/search-form/search-form-container";
import { AutocompleteInputHydrated } from "@app/components/search-form-hydrated/autocomplete-input-hydrated";
import { useIntlProvider } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-intl-provider";
import { openModal } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-modal-state";
import {
  getLegacySearchStateValues,
  useAffiliateOptin,
  useDefaultSearchLocationValues,
  useLegacySearchFormPassengers,
  useSearchLocationValues,
  useSearchStateActions
} from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-search-state-hooks";
import {
  ancillaryRedirectAffiliateAndShowBusbudResults,
  redirectToResultsPage
} from "@app/components/search-form-hydrated/autocomplete-input-hydrated/search-redirect-helpers";
import { CalendarModal } from "@app/components/search-form-hydrated/calendar-modal";
import { PassengersModal } from "@app/components/search-form-hydrated/passengers-modal";
import { SearchFormDevTools } from "@app/components/search-form-hydrated/search-form-devtools";
import { getGlobalSearchFormState } from "@app/components/search-form-hydrated/search-form-errors";
import { shouldIncreaseReturnDate } from "@app/components/search-form-hydrated/search-form.helpers";
import { getPopUnderAffiliate } from "@app/helpers/affiliate";
import { useLiteAppContext } from "@app/helpers/hooks";
import { LocalStorage } from "@app/lib/bowser-storage/local-storage";
import { SEARCH_ERRORS } from "@app/modules/search/constants";
import { getPassengerCountTranslation } from "@app/modules/search/helpers/passenger-input";
import { saveRecentSearch } from "@app/modules/search/helpers/recent-searches";
import { getSearchQuery } from "@app/modules/search/helpers/submit";
import { useDateInputs } from "@app/modules/search/store/hooks/date-inputs";
import { usePassengers } from "@app/modules/search/store/hooks/passengers";
import { isMissingAges } from "@app/modules/search/store/selectors/passengers";
import {
  setOutboundDate,
  setReturnDate
} from "@app/modules/search/store/slices/search-form";
import {
  clickedAffiliateCheckbox,
  clickedReverseCities,
  clickedSearchDate,
  clickedSearchPassenger,
  failedRouteSearch,
  searchedRoute,
  selectedDepartureDate,
  selectedReturnDate,
  unselectedReturnDate
} from "@app/tracking/search-tracking";
import { SearchFormMergedState } from "@app/types/search-types";
import {
  formatToUtcDate,
  getTomorrowDateFormatted,
  getUTCDate
} from "@app/utils/dates-without-moment";
import { appendQueryToCurrentLocation } from "@app/utils/window";

type CompactConfiguration = Partial<
  Record<"xs" | "sm" | "md" | "lg" | "xl", boolean>
>;

export interface SearchFormValues {
  origin: string;
  destination: string;
  outbound_date: string;
  return_date: string;
  affiliate_optin: boolean;
}

export interface Props {
  compact_configuration: CompactConfiguration;
  search_label: string;
  one_way_only?: boolean;
  with_affiliate_checkbox?: boolean;
  affiliate_checked?: boolean;
  style_variant?: SearchFormStyleVariant;
}

export const SearchFormHydrated: React.FC<Props> = ({
  compact_configuration,
  search_label,
  style_variant,
  with_affiliate_checkbox = false,
  affiliate_checked = false,
  one_way_only = false,
  ...props
}) => {
  const { entity, liteTranslator, features, tracker } = useLiteAppContext();
  const { getLocalizedDate } = useIntlProvider();
  const dispatch = useDispatch();

  const isMountedRef = React.useRef(false);
  const { return_date, outbound_date } = useDateInputs();
  const initial_date_values = React.useRef({
    return_date,
    outbound_date
  });

  const { total_passengers_count, passengers } = usePassengers();
  const passengers_count_translation = getPassengerCountTranslation(
    liteTranslator,
    total_passengers_count
  );
  const legacy_passenger_state = useLegacySearchFormPassengers();
  const initial_legacy_passenger_state = React.useRef(legacy_passenger_state);

  const { origin, destination } = useDefaultSearchLocationValues();
  const initial_locations = React.useRef({
    initial_origin: origin.value,
    initial_destination: destination.value
  });
  const { recent_searches } = useSearchLocationValues();
  const { swapCities } = useSearchStateActions();

  const affiliate_optin_redux = useAffiliateOptin();

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors, isDirty }
  } = useForm<SearchFormValues>({
    defaultValues: {
      origin: origin.formatted,
      destination: destination.formatted,
      outbound_date: outbound_date || "",
      return_date: return_date || "",
      affiliate_optin: affiliate_checked
    }
  });

  const passengerInputRef = React.useRef<HTMLInputElement>(null);
  const outboundInputRef = React.useRef<HTMLInputElement>(null);
  const returnInputRef = React.useRef<HTMLInputElement>(null);

  const is_results = !!(entity && entity.type === "results");
  const is_disabled =
    is_results && features.SEARCH_FORM_DISABLE_LOCATION_INPUTS_ON_RESULTS;

  // Dynamic search button: if the form state has changed, highlight the search button
  const is_passenger_state_updated =
    initial_legacy_passenger_state.current !== legacy_passenger_state;
  const is_date_state_updated = !isEqual(initial_date_values.current, {
    return_date,
    outbound_date
  });
  const is_form_state_updated: boolean =
    isDirty || is_passenger_state_updated || is_date_state_updated;

  React.useEffect(
    function syncOutboundDateInputValues() {
      const prev_date = getValues("outbound_date");
      let new_outbound_date = outbound_date;

      if (!isMountedRef.current) {
        if (!new_outbound_date) {
          new_outbound_date = getTomorrowDateFormatted();
          dispatch(setOutboundDate(new_outbound_date));
        }
        isMountedRef.current = true;
      }

      if (!new_outbound_date) {
        return;
      }

      setValue("outbound_date", new_outbound_date);

      const return_date = getValues("return_date");
      if (shouldIncreaseReturnDate(new_outbound_date, return_date)) {
        const utc_date = getUTCDate(new_outbound_date);
        utc_date.setDate(utc_date.getDate() + 1);
        const formatted_date = formatToUtcDate(utc_date);

        dispatch(setReturnDate(formatted_date));
      }

      if (prev_date && prev_date !== new_outbound_date) {
        tracker?.asyncTrack(
          selectedDepartureDate(prev_date, new_outbound_date)
        );
      }
    },
    [outbound_date, setValue, getValues, tracker, dispatch]
  );

  React.useEffect(
    function syncReturnDateInputValues() {
      const prev_date = getValues("return_date");
      setValue("return_date", return_date || "");

      if (return_date) {
        tracker?.asyncTrack(selectedReturnDate(prev_date ?? null, return_date));
      } else if (prev_date && !return_date) {
        tracker?.asyncTrack(unselectedReturnDate());
      }
    },
    [return_date, setValue, getValues, tracker]
  );

  React.useEffect(
    function syncAffiliateInputValue() {
      // If there's no checkbox, don't enable the affiliate optin
      if (!with_affiliate_checkbox) {
        setValue("affiliate_optin", false);
        return;
      }
      setValue("affiliate_optin", affiliate_optin_redux);
    },
    [with_affiliate_checkbox, affiliate_optin_redux, setValue]
  );

  const onSubmit: SubmitHandler<SearchFormValues> = async data => {
    const { outbound_date, return_date, affiliate_optin } = data;
    const legacy_state_values = getLegacySearchStateValues();
    const { lang, whitelabel, push_state_disabled } = legacy_state_values;

    const state: Omit<SearchFormMergedState, "passengers"> = {
      origin: origin.value,
      destination: destination.value,
      affiliate_optin,
      outbound_date,
      return_date,
      vehicle_category: undefined,
      ...legacy_passenger_state,
      ...legacy_state_values
    };

    if (!(origin.value && destination.value && outbound_date)) {
      return;
    }

    const search_with_accomodation_affiliate = affiliate_optin
      ? getPopUnderAffiliate()
      : "none";

    if (
      isMissingAges({
        search_form: {
          passengers
        }
      })
    ) {
      tracker?.asyncTrack(
        failedRouteSearch({
          state: {
            ...state,
            origin: origin.value,
            destination: destination.value,
            outbound_date
          },
          errors: [SEARCH_ERRORS.MISSING_AGE],
          search_with_accomodation_affiliate
        })
      );
      openModal("passengers");
      return;
    }

    const results_options = {
      lang,
      features,
      whitelabel,
      state,
      tracker
    };

    const browser_storage = LocalStorage.fromWindow(window);

    saveRecentSearch(browser_storage, "origin", recent_searches.origin);
    saveRecentSearch(
      browser_storage,
      "destination",
      recent_searches.destination
    );

    // Tracking
    const { initial_origin, initial_destination } = initial_locations.current;
    tracker?.track(
      searchedRoute({
        state: {
          ...state,
          origin: origin.value,
          destination: destination.value,
          outbound_date
        },
        initial_origin,
        initial_destination,
        search_with_accomodation_affiliate
      })
    );

    // Redirect
    if (!push_state_disabled) {
      // This allows us to fill the form when the user clicks back from results page
      appendQueryToCurrentLocation(getSearchQuery(results_options, state));
    }

    if (search_with_accomodation_affiliate !== "none") {
      await ancillaryRedirectAffiliateAndShowBusbudResults(
        search_with_accomodation_affiliate,
        results_options
      );
      return;
    }

    redirectToResultsPage(results_options);
  };

  const handleLocationSwap = React.useCallback(() => {
    const { origin: origin_value, destination: destination_value } =
      getValues();
    setValue("origin", destination_value);
    setValue("destination", origin_value);
    swapCities();

    const { origin, destination } = getGlobalSearchFormState();
    tracker?.asyncTrack(clickedReverseCities(origin, destination));
  }, [swapCities, setValue, getValues, tracker]);

  const handleDateClick = React.useCallback(
    (direction: "outbound" | "return") => {
      openModal(direction === "outbound" ? "outbound_date" : "return_date");

      tracker?.asyncTrack(clickedSearchDate(direction));
    },
    [tracker]
  );

  const handlePassengerClick = React.useCallback(() => {
    openModal("passengers");
    tracker?.asyncTrack(clickedSearchPassenger());
  }, [tracker]);

  return (
    <>
      <SearchFormContainer
        data-testid="search-form-hydrated"
        onSubmit={handleSubmit(onSubmit)}
        style_variant={style_variant}
        {...props}
      >
        <LocationGroup
          one_way_only={one_way_only}
          originInputSection={
            <Controller
              control={control}
              name="origin"
              rules={{ required: true }}
              render={({ field }) => (
                <AutocompleteInputHydrated
                  type="origin"
                  isInvalid={!!errors.origin}
                  getValues={getValues}
                  isDisabled={is_disabled}
                  {...field}
                />
              )}
            />
          }
          swapLocationSection={
            <UnstyledButton
              role="button" // TODO: Remove role after fix in horizon
              id="swap-cities-icon"
              data-testid="swap-cities-button"
              className="border rotate-45 rounded-md border-width-sm border-color-primary bg-color-canvas-primary p-100 active:bg-color-canvas-secondary sm:border-color-static-transparent sm:p-075 sm:hover:border-color-primary"
              ariaLabel={liteTranslator.t(
                "!landing.input-label.swap-locations"
              )}
              onClick={handleLocationSwap}
              isDisabled={is_disabled}
            >
              <IconArrowLeftRight
                size="md"
                className="rotate-45 text-icon-color-primary sm:-rotate-45"
              />
            </UnstyledButton>
          }
          destinationInputSection={
            <Controller
              control={control}
              name="destination"
              rules={{ required: true }}
              render={({ field }) => (
                <AutocompleteInputHydrated
                  type="destination"
                  isInvalid={!!errors.destination}
                  getValues={getValues}
                  isDisabled={is_disabled}
                  {...field}
                />
              )}
            />
          }
        />
        <DatePassengerSearchGroup
          one_way_only={one_way_only}
          outboundDateInputSection={
            <Controller
              control={control}
              name="outbound_date"
              rules={{ required: true }}
              render={({ field: { value } }) => (
                <>
                  <Input
                    id="outbound-date-input"
                    labelText={liteTranslator.t(
                      "!search.input.outbound-date.label"
                    )}
                    ref={outboundInputRef}
                    value={getLocalizedDate(value)}
                    onClick={() => handleDateClick("outbound")}
                    className="no-background-rest h-full w-full"
                    type="text"
                    placeholder={" "}
                    isReadOnly
                  />
                  <CalendarModal
                    direction="outbound"
                    input_element={outboundInputRef}
                    selected_date={value}
                  />
                </>
              )}
            />
          }
          returnDateInputSection={
            <Controller
              control={control}
              name="return_date"
              render={({ field: { value } }) => (
                <>
                  <Input
                    id="return-date-input"
                    labelText={liteTranslator.t(
                      "!search.input.return-date.label"
                    )}
                    ref={returnInputRef}
                    value={getLocalizedDate(value)}
                    onClick={() => handleDateClick("return")}
                    className="no-background-rest h-full w-full"
                    type="text"
                    placeholder={liteTranslator.t(
                      "!search.input.return-date.placeholder"
                    )}
                    isReadOnly
                  />
                  <CalendarModal
                    direction="return"
                    input_element={returnInputRef}
                    selected_date={value}
                  />
                </>
              )}
            />
          }
          passengerInfoSection={
            <>
              <Input
                id="passenger-input"
                labelText={liteTranslator.t("!landing.input-label.passengers")}
                ref={passengerInputRef}
                onClick={handlePassengerClick}
                className="no-background-rest h-full w-full"
                type="text"
                placeholder={passengers_count_translation}
                aria-label={passengers_count_translation}
                value={passengers_count_translation}
                isReadOnly
              />
              <PassengersModal input_element={passengerInputRef} />
            </>
          }
          searchButtonSection={
            <SearchFormButton
              label={search_label}
              compact={compact_configuration}
              isSubmit
              isHighlighted={is_form_state_updated}
            />
          }
        />
      </SearchFormContainer>
      {!!with_affiliate_checkbox && (
        <Controller
          control={control}
          name="affiliate_optin"
          render={({ field: { ref, value, onChange, ...field } }) => (
            <AffiliateCheckbox
              className="mt-150"
              isDefaultChecked={value}
              onChange={e => {
                onChange(e);
                tracker?.asyncTrack(
                  clickedAffiliateCheckbox(
                    getPopUnderAffiliate(),
                    !!(e?.currentTarget as HTMLInputElement).checked
                  )
                );
              }}
              {...field}
            />
          )}
        />
      )}
      <SearchFormDevTools control={control} />
    </>
  );
};
