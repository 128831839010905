import {
  AffiliatePartners,
  BOOKING_COM_AID,
  EXPEDIA_AID
} from "@app/constants/affiliate-partners";
import { REDIRECT_HOSTS } from "@app/constants/redirect";
import { Features } from "@app/types/experiments";
import {
  SearchFormMergedState,
  SearchLocationQuery,
  SearchPlace,
  SearchQuery
} from "@app/types/search-types";

import { Whitelabel } from "@partners/partner.type";

import * as SearchHelpers from "../../../helpers/search-client";
import { computeQueryString } from "../../../utils";

function toParsedQs(query: SearchQuery): qs.ParsedQs {
  const {
    outbound_date,
    return_date,
    whitelabel,
    origin_location,
    destination_location,
    profile_ids,
    ...rest
  } = query;

  return {
    ...(outbound_date && { outbound_date }),
    ...(return_date && { return_date }),
    ...(whitelabel !== undefined ? { whitelabel: `${whitelabel}` } : {}),
    ...(origin_location && {
      origin_location: toStringRecord(origin_location)
    }),
    ...(destination_location && {
      destination_location: toStringRecord(destination_location)
    }),
    ...{ profile_ids: profile_ids?.map(String) },
    ...rest
  };
}

function toStringRecord(
  location_query: SearchLocationQuery
): Record<string, string> {
  return Object.fromEntries(
    Object.entries(location_query).map(([key, value]) => [key, `${value}`])
  );
}

function getGeohashOrGeoidFromSearchPlace(
  use_geoid: boolean,
  search_place: SearchPlace | null
): string | undefined {
  return use_geoid
    ? search_place?.location?.geo_entity_id ?? search_place?.city.id
    : search_place?.city.geohash;
}

export function getResultsPageUrl(
  options: {
    whitelabel?: Whitelabel;
    lang: string;
    features: Partial<Features>;
  },
  state: Omit<SearchFormMergedState, "passengers">
): string {
  const { origin, destination, discount_code } = state;
  const use_geoid = options.features.RESULTS_INVENTORY_GEO_ENTITIES ?? false;

  const search = Object.assign({}, state, {
    origin: getGeohashOrGeoidFromSearchPlace(use_geoid, origin),
    destination: getGeohashOrGeoidFromSearchPlace(use_geoid, destination),
    ...(!use_geoid && {
      origin_location_ids: origin?.location?.id?.map(Number),
      destination_location_ids: destination?.location?.id?.map(Number)
    }),
    discount_code
  });

  const params = SearchHelpers.getUrlParams(search);
  const query_data = SearchHelpers.getUrlQuery(search);
  query_data.whitelabel =
    options.whitelabel && options.whitelabel.length
      ? options.whitelabel
      : undefined;

  const url = `/${options.lang}/bus-schedules-results/${params.from}/${params.to}`;

  return url + computeQueryString(toParsedQs(query_data));
}

export function getSearchQuery(
  options: {
    whitelabel?: Whitelabel;
    lang: string;
    features: Partial<Features>;
  },
  state: Pick<SearchFormMergedState, "origin" | "destination">
): SearchQuery {
  const { origin, destination } = state;
  const use_geoid = options.features.RESULTS_INVENTORY_GEO_ENTITIES ?? false;

  const search = Object.assign({}, state, {
    origin: getGeohashOrGeoidFromSearchPlace(use_geoid, origin),
    destination: getGeohashOrGeoidFromSearchPlace(use_geoid, destination),
    origin_location_ids: origin?.location?.id,
    destination_location_ids: destination?.location?.id
  });

  const params = SearchHelpers.getUrlParams(search);
  // @ts-expect-error
  const query_data = Object.assign(SearchHelpers.getUrlQuery(search), {
    origin_geohash: params.from,
    destination_geohash: params.to
  });

  query_data.whitelabel =
    options.whitelabel && options.whitelabel.length
      ? options.whitelabel
      : undefined;

  return query_data;
}

export type SearchAffiliateComQueryState = Omit<
  Partial<SearchFormMergedState>,
  "destination" | "outbound_date"
> & {
  affiliate_name: AffiliatePartners;
  destination?: { city: { id: string } } | null;
  outbound_date: string | null;
};
export function getSearchAffiliateQuery(
  state: SearchAffiliateComQueryState,
  options: { interstitial: boolean } = { interstitial: true }
): string {
  if (state.affiliate_name === AffiliatePartners.BOOKING_COM) {
    return generateSearchAffiliateComQuery(
      state,
      getBookingComQueryData(),
      options
    );
  } else if (state.affiliate_name === AffiliatePartners.EXPEDIA) {
    return generateSearchAffiliateComQuery(
      state,
      getExpediaQueryData(),
      options
    );
  }

  throw new Error(`Unsupported affiliate: ${state.affiliate_name}`);
}

function getBookingComQueryData() {
  return {
    hostname: REDIRECT_HOSTS[AffiliatePartners.BOOKING_COM],
    aid: BOOKING_COM_AID.SEARCH
  };
}

function getExpediaQueryData() {
  return {
    hostname: REDIRECT_HOSTS[AffiliatePartners.EXPEDIA],
    aid: EXPEDIA_AID.SEARCH
  };
}

function generateSearchAffiliateComQuery(
  state: SearchAffiliateComQueryState,
  affiliate_query_data: Record<string, string> = {},
  options: { interstitial: boolean } = { interstitial: true }
): string {
  const {
    outbound_date,
    destination,
    return_date,
    locale,
    adult,
    child,
    senior
  } = state;

  const {
    city: { id: destination_city_id }
  } = destination || { city: { id: undefined } };

  const getValidQueryValue = (value: string | null | undefined) =>
    value ? value : undefined;

  const device_id =
    typeof window !== "undefined"
      ? window.amplitude?.getInstance()?.options?.deviceId
      : null;

  const query_data = {
    outbound_date: getValidQueryValue(outbound_date),
    return_date: getValidQueryValue(return_date),
    destination_city: getValidQueryValue(destination_city_id),
    locale,
    device_id,
    adult,
    child,
    senior,
    ...affiliate_query_data,
    ...(options?.interstitial && { interstitial: true })
  };

  return "/tpr" + computeQueryString(toParsedQs(query_data));
}
