import React from "react";

import clsx from "clsx";
import { UseFormGetValues } from "react-hook-form";

import { Dialog } from "@app/components/dialog";
import { AutocompleteDataset } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/autocomplete-dataset";
import { AutocompletePredictionDatasetOptions } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/autocomplete-input-hydrated";
import { useAutocompleteInput } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-autocomplete-input";
import { NapiFlexLocationOption } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-napi-predictions";
import { SearchFormValues } from "@app/components/search-form-hydrated/search-form-hydrated";
import { useLiteAppContext } from "@app/helpers/hooks";
import { SearchLocationType } from "@app/types/search-types";

interface AutocompleteInputModalProps {
  type: SearchLocationType;
  autocomplete_dataset_options: AutocompletePredictionDatasetOptions;
  open: boolean;
  onOptionFocus: (option: NapiFlexLocationOption) => void;
  onOptionSelect: (option: NapiFlexLocationOption) => void;
  onClose: () => void;
  getValues: UseFormGetValues<SearchFormValues>;
  renderHeader: () => React.ReactNode;
  renderLoadingSpinner: () => React.ReactNode;
  value: string; // TODO: remove after prop added to `Input`
}

export const AutocompleteInputModal: React.FC<AutocompleteInputModalProps> = ({
  type,
  autocomplete_dataset_options,
  open,
  onClose,
  onOptionFocus,
  renderHeader,
  renderLoadingSpinner,
  onOptionSelect,
  getValues
}) => {
  const { liteTranslator } = useLiteAppContext();
  const { suggestions, recent_searches } = autocomplete_dataset_options;

  const { handleOptionClick, handleOptionFocus, focusedOption } =
    useAutocompleteInput({
      location_type: type,
      onOptionSelect,
      onOptionFocus,
      getValues
    });

  if (!open) {
    return null;
  }

  return (
    <Dialog
      data-testid={`${type}-modal`}
      id={`${type}-modal`}
      isOpen={open}
      onClose={onClose}
      className={clsx(
        "mobile-modal-container m-0 max-h-full max-w-full rounded-none",
        {
          ["animate-slideInFromRight"]: open && type === "destination"
        }
      )}
    >
      <Dialog.Body
        // `z-[1]` - places the header above the content
        className="sticky top-0 z-[1] border-b-width-sm border-b-color-primary bg-color-glass-primary backdrop-blur-md"
      >
        {renderHeader()}
      </Dialog.Body>
      <Dialog.Body className="js-suggestions-container mobile-modal-content flex flex-col">
        <AutocompleteDataset
          location_options={recent_searches.location_options}
          label={liteTranslator.t("!search.input.location.recent.label")}
          is_recent_search={!!recent_searches.location_options.length}
          with_footer={!!suggestions.location_options.length}
          focused_option={focusedOption}
          handleOptionClick={handleOptionClick}
          handleOptionFocus={handleOptionFocus}
        />
        <AutocompleteDataset
          location_options={suggestions.location_options}
          label={liteTranslator.t("!search.input.location.suggestion.label")}
          focused_option={focusedOption}
          handleOptionClick={handleOptionClick}
          handleOptionFocus={handleOptionFocus}
        />
        {renderLoadingSpinner()}
      </Dialog.Body>
    </Dialog>
  );
};
