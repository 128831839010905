import React from "react";

import { ListBox } from "@app/components/listbox";
import { getSuggestionLocationIcon } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/autocomplete-option/autocomplete-icons";
import { NapiFlexLocationOption } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-napi-predictions";
import {
  SuggestionIcon,
  SuggestionIconType
} from "@app/modules/search/suggestion/suggestion-icon";

interface CitySuggestionItemProps extends React.HTMLAttributes<HTMLDivElement> {
  suggestion: NapiFlexLocationOption;
  isActive: boolean;
}

export const CitySuggestionItem: React.FC<CitySuggestionItemProps> = React.memo(
  ({ suggestion, ...props }) => {
    const { full_name, location } = suggestion.value;

    const [city_name, ...region_name] = full_name.split(",");

    let icon: SuggestionIconType = "city";

    if (location) {
      icon = getSuggestionLocationIcon(location.stop_type);
    }

    return (
      <ListBox.Option
        iconStart={<SuggestionIcon type={icon} hidden={false} />}
        title={city_name}
        subtitle={region_name.join(", ")}
        detail={location?.full_name}
        {...props}
      />
    );
  }
);
