import React from "react";

import { ListBox } from "@app/components/listbox";
import { getSuggestionLocationIcon } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/autocomplete-option/autocomplete-icons";
import { NapiFlexLocationOption } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-napi-predictions";
import {
  SuggestionIcon,
  SuggestionIconType
} from "@app/modules/search/suggestion/suggestion-icon";

interface LocationSuggestionItemProps
  extends React.HTMLAttributes<HTMLDivElement> {
  suggestion: NapiFlexLocationOption;
}

export const LocationSuggestionItem: React.FC<LocationSuggestionItemProps> =
  React.memo(({ suggestion, ...props }) => {
    const { location } = suggestion.value;
    const icon: SuggestionIconType = getSuggestionLocationIcon(
      location?.stop_type
    );
    if (!location) {
      return null;
    }
    return (
      <ListBox.Option
        iconStart={<SuggestionIcon size="sm" type={icon} hidden={false} />}
        title={location?.full_name}
        isChild
        {...props}
      />
    );
  });
