import { proxy, ref } from "valtio";
import { useSnapshot } from "valtio/react";

type ModalOption =
  | "origin"
  | "destination"
  | "outbound_date"
  | "return_date"
  | "passengers"
  | null;

interface SearchFormModalState {
  open_input: ModalOption;
  input_refs: {
    origin: HTMLInputElement | null;
    destination: HTMLInputElement | null;
    outbound_date: HTMLInputElement | null;
    return_date: HTMLInputElement | null;
    passengers: HTMLInputElement | null;
  };
}

const search_form_modal_state = proxy<SearchFormModalState>({
  open_input: null,
  input_refs: {
    origin: null,
    destination: null,
    outbound_date: null,
    return_date: null,
    passengers: null
  }
});

export const openModal = (option: ModalOption) => {
  search_form_modal_state.open_input = option;
};

export const closeModal = () => {
  search_form_modal_state.open_input = null;
};

export const useModalState = () => {
  return useSnapshot(search_form_modal_state);
};

export const useIsOutboundDateModalOpen = () => {
  const state = useSnapshot(search_form_modal_state);
  return state.open_input === "outbound_date";
};

export const useIsReturnDateModalOpen = () => {
  const state = useSnapshot(search_form_modal_state);
  return state.open_input === "return_date";
};

export const useIsPassengersModalOpen = () => {
  const state = useSnapshot(search_form_modal_state);
  return state.open_input === "passengers";
};

export const setInputRef = (
  option: ModalOption,
  inputRef: HTMLInputElement | null
) => {
  if (!option || !inputRef) {
    return;
  }
  search_form_modal_state.input_refs[option] = ref(inputRef);
};

export const getInputRef = (option: ModalOption) => {
  if (!option) {
    return null;
  }
  return search_form_modal_state.input_refs[option];
};
