import React from "react";

import { mergeClasses } from "@busbud/horizon";

import {
  search_form_classes,
  useSearchLayoutClasses
} from "@app/components/search-form/search-form.styles";
import { useSeatSelectionFirst } from "@app/modules/seat-selection-first/hooks/use-seat-selection-first";

interface DatePassengerSearchGroupProps {
  one_way_only: boolean;
  outboundDateInputSection: React.ReactNode;
  returnDateInputSection: React.ReactNode;
  passengerInfoSection: React.ReactNode;
  searchButtonSection: React.ReactNode;
}

export const DatePassengerSearchGroup: React.FC<
  DatePassengerSearchGroupProps
> = ({
  one_way_only,
  outboundDateInputSection,
  returnDateInputSection,
  passengerInfoSection,
  searchButtonSection
}) => {
  const {
    date_passenger_and_search_button_group_classes,
    date_and_passenger_group_classes,
    date_group_classes,
    outbound_date_classes,
    return_date_classes,
    passengers_details_classes,
    search_button_classes
  } = useSearchLayoutClasses(one_way_only);
  const { is_seat_selection_first_enabled } = useSeatSelectionFirst();

  return (
    <div
      className={mergeClasses(
        ...date_passenger_and_search_button_group_classes,
        "relative flex flex-wrap sm:max-lg:rounded-md sm:max-lg:bg-color-canvas-primary sm:max-lg:shadow-md md:flex-nowrap"
      )}
    >
      <div
        className={mergeClasses(
          ...date_and_passenger_group_classes,
          "flex flex-wrap sm:flex-nowrap"
        )}
      >
        <div
          className={mergeClasses(
            ...date_group_classes,
            "flex flex-wrap max-sm:mb-100 max-sm:rounded-md max-sm:bg-color-canvas-primary max-sm:shadow-md sm:border-r-width-sm sm:border-r-color-primary"
          )}
        >
          <div
            className={mergeClasses(
              ...outbound_date_classes,
              search_form_classes.cell_padding
            )}
          >
            {outboundDateInputSection}
          </div>
          {!one_way_only && (
            <div
              className={mergeClasses(
                ...return_date_classes,
                "border-l-width-sm border-l-color-primary",
                search_form_classes.cell_padding
              )}
            >
              {returnDateInputSection}
            </div>
          )}
        </div>
        {!is_seat_selection_first_enabled && (
          <div
            data-testid="passenger-info-input"
            className={mergeClasses(
              ...passengers_details_classes,
              "max-sm:mb-100 max-sm:rounded-md max-sm:bg-color-canvas-primary max-sm:shadow-md sm:border-r-width-sm sm:border-r-color-primary",
              "relative", // for the passenger modal positioning
              search_form_classes.cell_padding
            )}
          >
            {passengerInfoSection}
          </div>
        )}
      </div>
      <div
        className={mergeClasses(...search_button_classes, "whitespace-nowrap")}
        data-cy="search-submit"
        id="search-submit-button"
      >
        {searchButtonSection}
      </div>
    </div>
  );
};
